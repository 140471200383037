import { ref } from "vue";
import { isServer } from "~/helpers/env";
import { useReport } from "~/composables/useReport";

let microModal = null as any;
export const anyOpenedModal = ref(false);

export const useModal = () => {
  const open = async (modalId: string) => {
    if (!microModal) {
      const modalInstance = await initModal();
      if (modalInstance) {
        setModalInsntace(modalInstance);
      }
    }
    microModal.show(modalId);
    anyOpenedModal.value = true;
  };
  const close = (modalId: string) => {
    if (!anyOpenedModal.value) {
      return;
    }
    if (!microModal) {
      useReport({
        level: "error",
        message: "modal is not initialized",
      });
      return;
    }
    microModal.close(modalId);
    anyOpenedModal.value = false;
  };
  return {
    open,
    close,
  };
};

export async function initModal() {
  try {
    if (isServer) {
      return;
    }
    const modal = await import("micromodal");
    if (modal) {
      modal.default.init();
      return modal.default;
    }
    throw new Error("modal.default is wrong");
  } catch (err) {
    useReport({
      level: "error",
      message: "failed load modal lib",
      errorException: err,
    });
    return null;
  }
}

export function setModalInsntace(microModalInstance: any) {
  microModal = microModalInstance;
}
